import React from "react";
import * as assets from "./assets";

const gridItems = [
  {
    href: "/VIBES",
    alt: "VIBES",
    front: assets.grid_vibes_f_gif,
    back: assets.grid_vibes_b_png,
    background: [
      "linear-gradient(to bottom right, #0084FF, #FF00F6, #EAFF00)",
      "linear-gradient(to bottom right, #00407f, #0072c9, #508dda, #c76d9b, #fb1e6f)",
    ][/* TODO: change to 1 later */ 0],
  },
  // {
  //   href: "/KEVINTOADZ",
  //   alt: "kevin toadz",
  //   front: assets.grid_kevintoadz_f_png,
  //   back: assets.grid_kevintoadz_b_png,
  //   background: `url("${assets.grid_kevintoadz_bg_png}") center / cover`,
  // },
  // {
  //   href: "/DONKEYS",
  //   alt: "darryls deformed donkeys",
  //   front: assets.grid_donkeys_f_png,
  //   back: assets.grid_donkeys_b_png,
  //   background: "#E384BA",
  // },
  // {
  //   href: "/NFTL",
  //   alt: "NFTL",
  //   front: assets.grid_nftl_f_png,
  //   back: assets.grid_nftl_b_png,
  //   background: "#2141B5",
  // },
  // {
  //   alt: "me gusta me boosta l",
  //   front: assets.grid_gusta_f_png,
  //   back: assets.grid_gusta_b_png,
  //   background: "#FFB030",
  // },
  {
    href: "/checksmate",
    alt: "checksmate",
    front: assets.grid_checkmate_f_png,
    back: assets.grid_checkmate_b_png,
    background: "#dcdcdc",
  },
  // {
  //   href: "/VESTED",
  //   alt: "vested",
  //   front: assets.grid_vested_f_png,
  //   back: assets.grid_vested_b_png,
  //   background: "#dcdcdc",
  // },
];

const footerColors = [
  "#BE3A55",
  "#FFC118",
  "#2FAAE0",
  "#2DE0B7",
  "#E384BA",
  "#8546E3",
];
const footerColor = footerColors[(Math.random() * footerColors.length) | 0];

function App() {
  return (
    <div className="App">
      <div className="grid-container">
        {gridItems.map((item, i) => {
          const content = (
            <>
              <img
                src={item.front}
                alt={item.alt}
                className="front"
                loading="lazy"
              />
              {item.back ? (
                <img
                  src={item.back}
                  alt={item.alt}
                  className="back"
                  loading="lazy"
                />
              ) : (
                <span>
                  Coming
                  <br />
                  Soon
                </span>
              )}
            </>
          );
          return (
            <div
              className="grid-item"
              key={i}
              style={{
                background: item.background,
              }}
            >
              {item.href ? (
                <a href={item.href}>{content}</a>
              ) : (
                <div>{content}</div>
              )}
            </div>
          );
        })}
      </div>
      <footer className="footer" style={{ backgroundColor: footerColor }}>
        <div className="container">
          <div></div>
          <div>
            <h3>
              <img
                src={assets.made_with_vibes_text_png}
                alt="MADE WITH !VIBES"
                loading="lazy"
                width="1204"
                height="75"
              />
            </h3>
            <img
              onClick={() => window.open("https://twitter.com/popo", "_blank")}
              style={{ cursor: "pointer", imageRendering: "pixelated" }}
              src={assets.popo_logo_gif}
              alt="popo logo"
              loading="lazy"
              width="786"
              height="313"
            />
          </div>
          {/* <div>
            <a
              href="https://twitter.com/vibesbypopo"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={assets.twitter_logo_png}
                alt="twitter"
                loading="lazy"
                width="434"
                height="434"
              />
            </a>
          </div> */}
        </div>
      </footer>
    </div>
  );
}

export default App;
